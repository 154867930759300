import PropTypes from "prop-types";
import React, { useEffect, Image } from "react";
import styled from "styled-components";

import BottomSheet from "../../../../_shared/components/BottomSheet";
import Input from "../../../../_shared/components/FormItems/AppInput";

import useForm from "../../../../_shared/hooks/useForm";
import StyledImageUploadInput from "../../../../_shared/components/FormItems/ImageUploadInput";
import StyledUploadedImagePreview from "../../../../_shared/components/UploadedImagePreview";
import { FaFilePdf, FaImage } from "react-icons/fa";

// ----------------------------------------------------------------------------

function VolantiniFormBottomSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { open, post, onConfirm, volantini, ...sheetProps } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const initialValues = post ?? {};

  const [formValues, setFormValue, setFormValues, clearForm] =
    useForm(initialValues);

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (open) {
      setFormValues(post ?? {});
    } else {
      clearForm();
    }
  }, [open]);

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleFormValueChange(key) {
    return function (value) {
      console.log(">>> handleFormValueChange: ", key, value);
      setFormValue(key, value);
    };
  }

  function handleConfirm() {
    onConfirm?.(formValues);
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  console.log("Volantino: ", formValues);

  return (
    <BottomSheet
      className={props.className}
      open={open}
      snapPoint={80}
      title={post?.id ? "Modifica Volantino" : "Nuovo Volantino"}
      onConfirm={handleConfirm}
      {...sheetProps}
    >
      <Input
        className="form-control"
        label="Nome"
        value={formValues.name}
        onChange={handleFormValueChange("name")}
      />
      <h3>PDF</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Input
          className="form-control"
          label="Nome"
          value={formValues.pdf}
          onChange={handleFormValueChange("pdf")}
        />
        {/* <StyledImageUploadInput
          noPreview
          onUpload={handleFormValueChange("pdf")}
        ></StyledImageUploadInput>
        {typeof formValues?.pdf === "string" && (
          <a href={formValues?.pdf} target="_blank" rel="noopener noreferrer">
            <FaFilePdf className="pdf-icn" />
          </a>
        )} */}
      </div>
      <h3>IMMAGINE ANTEPRIMA</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <StyledImageUploadInput
          onUpload={handleFormValueChange("image")}
        ></StyledImageUploadInput>
        {typeof formValues?.image === "string" && (
          <img
            alt=""
            style={{
              marginLeft: 50,
              maxHeight: 200,
              objectFit: "contain",
            }}
            src={formValues?.image}
          />
        )}
      </div>
    </BottomSheet>
  );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

VolantiniFormBottomSheet.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  post: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
};

VolantiniFormBottomSheet.defaultProps = {};

// ----------------------------------------------------------------------------

const VolantiniFormBottomSheetWithStyle = styled(VolantiniFormBottomSheet)`
  & {
    .sheet-content {
      max-width: 1000px;

      .form-control {
        margin-bottom: 20px;
        width: 500px;
        max-width: 500px;
      }
    }
    .pdf-icn {
      margin-top: 50px;
      margin-left: 50px;
      font-size: 50px;
      color: ${(props) => props?.theme?.colors?.dropzoneTextColor};
    }
  }
`;
// ----------------------------------------------------------------------------

export default VolantiniFormBottomSheetWithStyle;
