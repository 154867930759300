import { Modal } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import Layout from "../../_shared/components/Layout";
import { useStateValue } from "../../_shared/context/AppStateStore";
import Settings from "./Settings";
import Stores from "./Stores";
import Volantini from "./Volantini";
import Coupons from "./Coupon";

// ----------------------------------------------------------------------------

function LoggedApp(props) {

  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [{ token }] = useStateValue();

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------



  return (
    <Layout>
      <Routes>
        <Route path="/stores" element={<Stores />} />
        <Route path="/volantini" element={<Volantini />} />
        <Route path="/coupons" element={<Coupons />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </Layout>
  );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

LoggedApp.propTypes = {
  className: PropTypes.string.isRequired,
};

LoggedApp.defaultProps = {};


// ----------------------------------------------------------------------------

export default LoggedApp;
