import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { message, Badge } from "antd";
import dayjs from "dayjs";

import * as api from "../../../../api/Local";
import AppButton from "../../../_shared/components/AppButton";
import AppInput from "../../../_shared/components/FormItems/AppInput";
import Table from "../../../_shared/components/Table";
import EditButton from "../../../_shared/components/Buttons/EditButton";
import DeleteButton from "../../../_shared/components/Buttons/DeleteButton";
import { useStateValue } from "../../../_shared/context/AppStateStore";
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";

import VolantiniFormBottomSheet from "./VolantiniFormBottomSheet";
import StyledPopconfirm from "../../../_shared/components/Popconfirm";

// ----------------------------------------------------------------------------

function Volantini(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [{ token }] = useStateValue();

  const [
    requests,
    fetchNewspaper,
    loading,
    pagination,
    totalRecordsNumber,
    onFiltersChange,
  ] = usePaginatedRestResource("/newspapers", token);

  const [selectedVolantino, setSelectedVolantino] = useState(null);

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function handleCreateVolantino(post) {
    try {
      let body = {
        ...post,
      };
      console.log("body is : ", body);
      if (post.pdf) {
        const uploadResponse = await api.uploadFile(post.pdf);

        body.pdf = uploadResponse?.url ?? body.pdf;
      }

      if (post.image) {
        const uploadResponse = await api.uploadFile(post.image);

        body.image = uploadResponse?.url ?? body.image;
      }

      await api.createResource("/newspapers", token, body);
      setSelectedVolantino(null);
      fetchNewspaper();
      message.success("Articolo creato con successo");
    } catch (error) {
      console.error(error);
      message.error("Si è verificato un errore. Riprovare più tardi");
    }
  }

  async function handleUpdateVolantino(post) {
    try {
      let body = {
        ...post,
      };

      if (post.pdf && typeof post.pdf !== "string") {
        const oldImage = requests?.find((item) => item?.id === post.id)?.pdf;

        const imageId = oldImage?.substring?.(oldImage?.lastIndexOf("/") + 1);

        const uploadResponse = await api.uploadFile(post.pdf);

        body.pdf = uploadResponse?.url ?? body.pdf;
      }

      if (post.image && typeof post.image !== "string") {
        const oldImage = requests?.find((item) => item?.id === post.id)?.image;

        const imageId = oldImage?.substring?.(oldImage?.lastIndexOf("/") + 1);

        const uploadResponse = await api.uploadFile(post.image);

        body.image = uploadResponse?.url ?? body.image;
      }

      await api.updateResource(
        `/newspapers`,
        token,
        selectedVolantino.id,
        body
      );
      setSelectedVolantino(null);
      fetchNewspaper();
      message.success("Articolo aggiornato con successo");
    } catch (error) {
      console.error(error);
      message.error("Si è verificato un errore. Riprovare più tardi");
    }
  }

  function handleVolantinoBottomSheetConfirm(post) {
    console.log("post :", post);
    if (post.id) {
      return handleUpdateVolantino(post);
    } else {
      return handleCreateVolantino(post);
    }
  }

  async function handleDeleteVolantino(post) {
    try {
      if (!post?.id) {
        return;
      }

      await api.deleteResource("/newspapers", token, post.id);
      fetchNewspaper();

      message.success("Articolo eliminato con successo");
    } catch (error) {
      message.error("Si è verificato un errore. Riprovare più tardi");
    }
  }

  function handleVolantinoBottomSheetCancel() {
    setSelectedVolantino(null);
  }

  function renderActions(row) {
    return (
      <div className="actions">
        <EditButton onClick={() => setSelectedVolantino(row)} />

        <StyledPopconfirm
          onConfirm={() => handleDeleteVolantino(row)}
          placement="left"
          title="Sei sicuro di voler eliminare questo articolo?"
        >
          <DeleteButton />
        </StyledPopconfirm>
      </div>
    );
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const defaultVolantino = {
    published: false,
  };

  return (
    <div className={`${className}`}>
      <h1 className="page-title">Volantini</h1>

      <AppButton
        uxType="secondary"
        onClick={() => setSelectedVolantino(defaultVolantino)}
      >
        Nuovo volantino
      </AppButton>

      <Table
        dataSource={requests}
        pagination={{ ...pagination, total: totalRecordsNumber }}
        onChange={(pagination) => fetchNewspaper({ pagination })}
        columns={getColumns(renderActions)}
        loading={loading}
      />

      <VolantiniFormBottomSheet
        open={selectedVolantino ? true : false}
        post={selectedVolantino}
        onCancel={handleVolantinoBottomSheetCancel}
        onConfirm={handleVolantinoBottomSheetConfirm}
      />
    </div>
  );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Volantini.propTypes = {
  className: PropTypes.string.isRequired,
};

Volantini.defaultProps = {};

// ----------------------------------------------------------------------------

const StyledVolantini = styled(Volantini)`
  & {
    .actions {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
`;
// ----------------------------------------------------------------------------

export default StyledVolantini;

function getColumns(renderActions) {
  return [
    {
      title: "Nome",
      key: "name",
      dataIndex: "name",
    },

    {
      key: "actions",
      render: renderActions,
    },
  ];
}
