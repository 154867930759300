import React, { useState, useEffect } from "react";
import PropTypes, { func } from "prop-types";
import styled from "styled-components";
import { message, Badge, Modal } from "antd";
import dayjs from "dayjs";

import * as api from "../../../../api/Local";
import AppButton from "../../../_shared/components/AppButton";
import AppInput from "../../../_shared/components/FormItems/AppInput";
import Table from "../../../_shared/components/Table";
import EditButton from "../../../_shared/components/Buttons/EditButton";
import DeleteButton from "../../../_shared/components/Buttons/DeleteButton";
import { useStateValue } from "../../../_shared/context/AppStateStore";
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";

import StoreFormBottomSheet from "./StoreFormBottomSheet";
import StyledPopconfirm from "../../../_shared/components/Popconfirm";
import Papa from "papaparse";

// ----------------------------------------------------------------------------

function Stores(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [{ token }] = useStateValue();

  const [
    stores,
    fetchStores,
    loading,
    pagination,
    totalRecordsNumber,
    onFiltersChange,
  ] = usePaginatedRestResource("/stores", token);

  const [
    volantini,
    fetchVolantini,
    loadingVol,
    paginationVol,
    totalRecordsNumberVol,
    onFiltersChangeVol,
  ] = usePaginatedRestResource("/newspapers", token);

  const [selectedStore, setSelectedStore] = useState(null);
  const [openSelectFile, setOpenSelectFile] = useState(null);
  const [importedStores, setImportedStores] = useState(null);

  dayjs.locale("it");
  var utc = require("dayjs/plugin/utc");
  var timezone = require("dayjs/plugin/timezone");

  dayjs.extend(utc);
  dayjs.extend(timezone);
  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function handleCreateStore(post) {
    try {
      console.log("post : ", post);
      let body = {
        ...post,
      };

      if (post.image) {
        const uploadResponse = await api.uploadFile(post.image);

        body.image = uploadResponse?.url ?? body.image;
      }

      await api.createResource("/stores", token, body);
      setSelectedStore(null);
      fetchStores();
      message.success("Articolo creato con successo");
    } catch (error) {
      console.error(error);
      message.error("Si è verificato un errore. Riprovare più tardi");
    }
  }

  function getHours(string) {
    if (string === "CHIUSO") {
      return { isClosed: true };
    }

    const times = string?.split("/")?.length;
    const morningHours = string?.split("/")[0];
    const morningStartTime = dayjs
      .utc(morningHours?.split("-")?.[0], "HH:mm")
      .format("HH:mm");

    const morningStopTime = dayjs
      .utc(morningHours?.split("-")?.[1], "HH:mm")
      .format("HH:mm");
    if (times === 2) {
      const afternoonHours = string?.split("/")[1];

      const afternoonStartTime = dayjs
        .utc(afternoonHours?.split("-")?.[0], "HH:mm")
        .format("HH:mm");
      const afternoonStopTime = dayjs
        .utc(afternoonHours?.split("-")?.[1], "HH:mm")
        .format("HH:mm");

      return {
        mStart: morningStartTime,
        mStop: morningStopTime,
        aStart: afternoonStartTime,
        aStop: afternoonStopTime,
        continuedTime: false,
      };
    } else {
      return {
        mStart: morningStartTime,
        mStop: morningStopTime,
        continuedTime: true,
      };
    }
  }

  async function getVolantino(url, newsPapersCreate) {
    let volantino = newsPapersCreate?.find((x) => x?.pdf == url);

    if (volantino) {
      return volantino?.id;
    } else {
      volantino = volantini?.find((x) => x?.pdf == url);
    }
    return volantino?.id;
  }

  async function createStructure(allPdfs) {
    let newsPapersCreate = [];
    Promise.all(
      allPdfs?.map(async (x) => {
        const body = {
          name: x?.split("/")?.pop(),
          pdf: x,
        };
        const vol = await api.createResource("/newspapers", token, body);
        newsPapersCreate.push(vol);
      })
    ).then(async () => {
      console.log("newsPapersCreate: ", newsPapersCreate);
      fetchVolantini();
      await createStores(newsPapersCreate);
    });
  }

  function getAllPdfs() {
    let newsPapersCreate = [];
    importedStores?.map((store) => {
      if (!volantini?.find((x) => x?.pdf === store?.["URL Volantino"])) {
        !newsPapersCreate?.includes(store?.["URL Volantino"]) &&
          newsPapersCreate?.push(store?.["URL Volantino"]);
      }
    });
    return newsPapersCreate;
  }

  async function getStoreBody(store, newsPapersCreate, existingStore) {
    const body = {
      code: store?.COD,
      name: store?.Nome,
      address: {
        CAP: store?.CAP,
        city: store?.Città,
        address: store?.Indirizzo,
        long: store?.Longitudine,
        lat: store?.Latitudine,
        region: store?.["Prov."],
      },
      phone: store?.Telefono,
      services: {
        Maxistore: store?.Maxistore === "MAXI",
        Parafarmacia: store?.Parafarmacia === "PAR",
        AlimentiSpeciali: store?.["Alimenti speciali"] === "ALIMENTI",
      },
      openingHours: {
        monday: getHours(store?.Lun),
        tuesday: getHours(store?.Mar),
        wednesday: getHours(store?.Mer),
        thursday: getHours(store?.Gio),
        friday: getHours(store?.Ven),
        saturday: getHours(store?.Sab),
        sunday: getHours(store?.Dom),
      },
      linkedVolantino: await getVolantino(
        store?.["URL Volantino"],
        newsPapersCreate
      ),
    };
    return body;
  }

  async function createStores(newsPapersCreate) {
    importedStores?.map(async (store) => {
      stores?.find((x) => x?.code === store?.COD)
        ? await handleUpdateStore(
            await getStoreBody(store, newsPapersCreate),
            stores?.find((x) => x?.code === store?.COD)?.id
          )
        : await handleCreateStore(await getStoreBody(store, newsPapersCreate));
    });
  }

  async function handleUpdateStore(post, id) {
    try {
      let body = {
        ...post,
        linkedVolantino: post?.linkedVolantino?.id
          ? post?.linkedVolantino?.id
          : post?.linkedVolantino,
      };

      await api.updateResource(`/stores`, token, id, body);
      setSelectedStore(null);
      fetchStores();
      message.success("Articolo aggiornato con successo");
    } catch (error) {
      console.error(error);
      message.error("Si è verificato un errore. Riprovare più tardi");
    }
  }

  function handleStoreBottomSheetConfirm(post) {
    if (post.id) {
      return handleUpdateStore(post, post.id);
    } else {
      return handleCreateStore(post);
    }
  }

  async function startCreation() {
    await createStructure(getAllPdfs());
  }

  async function handleDeleteStore(post) {
    try {
      if (!post?.id) {
        return;
      }

      await api.deleteResource("/stores", token, post.id);
      fetchStores();

      message.success("Articolo eliminato con successo");
    } catch (error) {
      message.error("Si è verificato un errore. Riprovare più tardi");
    }
  }

  function handleStoreBottomSheetCancel() {
    setSelectedStore(null);
  }

  function renderActions(row) {
    return (
      <div className="actions">
        <EditButton onClick={() => setSelectedStore(row)} />

        <StyledPopconfirm
          onConfirm={() => handleDeleteStore(row)}
          placement="left"
          title="Sei sicuro di voler eliminare questo articolo?"
        >
          <DeleteButton />
        </StyledPopconfirm>
      </div>
    );
  }

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setImportedStores(results.data);
      },
    });
  };

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const defaultStore = {
    published: false,

    disabled: false,

    services: {
      Maxistore: false,
      Parafarmacia: false,
      AlimentiSpeciali: false,
    },
    openingHours: {
      monday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      tuesday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      wednesday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      thursday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      friday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      saturday: {
        mStart: "08:00",
        mStop: "13:00",
        aStart: "15:30",
        aStop: "19:00",
        continuedTime: false,
      },
      sunday: {
        isClosed: true,
      },
    },
  };

  return (
    <div className={`${className}`}>
      <h1 className="page-title">Stores</h1>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <AppButton
          uxType="secondary"
          onClick={() => setSelectedStore(defaultStore)}
        >
          Nuovo Store
        </AppButton>
        <div style={{ marginLeft: 10 }}>
          <AppButton uxType="secondary" onClick={() => setOpenSelectFile(true)}>
            Importa file
          </AppButton>
        </div>
      </div>

      <Table
        dataSource={stores}
        pagination={{ ...pagination, total: totalRecordsNumber }}
        onChange={(pagination) => fetchStores({ pagination })}
        columns={getColumns(renderActions)}
        loading={loading}
      />

      <StoreFormBottomSheet
        volantini={volantini}
        open={selectedStore ? true : false}
        post={selectedStore}
        onCancel={handleStoreBottomSheetCancel}
        onConfirm={handleStoreBottomSheetConfirm}
      />
      <Modal
        cancelButtonProps={{ style: { display: "none" } }}
        open={openSelectFile}
        onOk={() => startCreation()}
        onCancel={() => setOpenSelectFile(false)}
      >
        <input
          onChange={changeHandler}
          type="file"
          name="file"
          accept=".csv"
          style={{ display: "block", margin: "10px auto" }}
        />
      </Modal>
    </div>
  );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Stores.propTypes = {
  className: PropTypes.string.isRequired,
};

Stores.defaultProps = {};

// ----------------------------------------------------------------------------

const StyledStores = styled(Stores)`
  & {
    .actions {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
`;
// ----------------------------------------------------------------------------

export default StyledStores;

function getColumns(renderActions) {
  return [
    {
      title: "",
      key: "disabled",
      dataIndex: "disabled",
      // eslint-disable-next-line
      render: (disabled) =>
        disabled ? <Badge status="error" /> : <Badge status="success" />,
    },
    {
      title: "Nome",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Codice",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "Indirizzo",
      key: "address",
      dataIndex: "address",
    },

    {
      key: "actions",
      render: renderActions,
    },
  ];
}
