import { Modal, message } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";

// import { publishCMS } from "../../../../api/Local"
import { useStateValue } from "../../context/AppStateStore";

import usePaginatedRestResource from "../../hooks/usePaginatedRestResource";
import AppButton from "../AppButton";
import Menu from "./Menu";

// ----------------------------------------------------------------------------

function _Layout(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------
  const [{ token }] = useStateValue();
  const [submitting, setSubmitting] = useState(false);

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={`${className}`}>
      <Menu />
      <main className="main-content">
        {props.children}
      </main>
    </div>
  );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Layout.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.object,
};

_Layout.defaultProps = {};

// ----------------------------------------------------------------------------

const Layout = styled(_Layout)`
  & {
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    background-color: ${(props) => props.theme.colors.primary};

    .main-content {
      flex: 1;
      padding: 20px;
      padding-top: 30px;
      overflow-y: auto;
      overflow-x: hidden;

      background-color: white;

      border-radius: 6px 0 0 6px;

      

      .page-title {
        font-size: 24px;
        font-weight: 600;
        font-family: Ibm;
        margin-bottom: 30px;
      }
    }
  }
`;
// ----------------------------------------------------------------------------

export default Layout;
