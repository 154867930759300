import { message, Tag } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  createSettings,
  getSettings,
  updateSettings,
} from "../../../../api/Local";

import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";
import AppButton from "../../../_shared/components/AppButton";
import AppInput from "../../../_shared/components/FormItems/AppInput";
import { useStateValue } from "../../../_shared/context/AppStateStore";
import Table from "../../../_shared/components/Table";
import dayjs from "dayjs";

// ----------------------------------------------------------------------------

function _Settings(props) {
  // -------------------------------------

  const { className } = props;
  const [minimumVersionAccepted, setMinimumVersionAccepted] = useState();
  const [{ token }] = useStateValue();

  useEffect(() => {
    fetchSettings();
  }, []);

  async function fetchSettings() {
    try {
      const sets = await getSettings(token);
      console.log(sets.minVersionAccepted);
      {
        sets?.minVersionAccepted === undefined
          ? console.log("no min version found")
          : setMinimumVersionAccepted(sets.minVersionAccepted);
      }
    } catch (error) {
      console.error("error: ", error.message);
    }
  }

  function checkValue(value) {
    if (/^(\d+\.)?(\d+\.)?(\d+)$/.test(value)) {
      return true;
    }
    return false;
  }

  async function saveSettings(e) {
    try {
      if (!checkValue(minimumVersionAccepted)) {
        message.error("Valore versione minima non valido");
        return;
      }
      const properties = { minimumVersionAccepted };
      const settings = await getSettings(token);
      {
        settings?.minVersionAccepted === undefined
          ? await createSettings(properties, token)
          : await updateSettings(properties, token);
      }

      message.success("Elementi aggiornati con successo");
    } catch (error) {
      console.log(e);
      message.error("Errore durante l'aggiornamento degli elementi");
    }
  }

  return (
    <div className={`${className}`}>
      <h1 className="page-title">Impostazioni</h1>

      <div className="form-control">
        Versione minima accettata
        <AppInput
          className="mt-5"
          value={minimumVersionAccepted}
          onChange={setMinimumVersionAccepted}
          label="Versione minima accettata"
        ></AppInput>
      </div>
      <div className="buttons-row">
        <AppButton onClick={saveSettings}>Salva</AppButton>
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Settings.propTypes = {
  className: PropTypes.string,
};

// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
const Settings = styled(_Settings)`
  & {
    .form-control {
      display: flex;
      flex-direction: column;

      .mt-5 {
        margin-top: 5px;
      }
    }

    .buttons-row {
      margin-top: 25px;
    }

    .syncs-container {
      margin-top: 25px;
    }
  }
`;
export default Settings;

// ----------------------------------------------------------------------------

const STATUSES_LABEL = {
  in_progress: "In corso",
  success: "Completato",
  error: "In errore",
};

const STATUSES_COLOR = {
  in_progress: "blue",
  success: "green",
  error: "red",
};

function getColumns() {
  return [
    {
      title: "Data pubblicazione",
      key: "updatedAt",
      dataIndex: "updatedAt",
      render: (text) => dayjs(text)?.format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Stato",
      key: "status",
      dataIndex: "status",
      //eslint-disable-next-line
      render: (text) => (
        <Tag color={STATUSES_COLOR[text]}>{STATUSES_LABEL[text]}</Tag>
      ),
    },
  ];
}
